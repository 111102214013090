import { db } from '@/firebase'

class WaitlistService {
	getAll() {
		return db;
	}
	signup(data) {
		return db.collection('waitlist').add(data)
	}
}

export default new WaitlistService();
